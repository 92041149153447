<template>
  <div class="auth-box">
    <!-- 未认证 -->
    <div v-if="authType === 1" class="not-auth">
      <!-- <div class="container">
        <div class="title">账号认证优势</div>
        <div class="top">
          <div class="list-box">
            <div v-for="(item, index) in advantageData" :key="index" class="item">
              <img :src="`/images/auth/icon-${index + 1}.svg`" alt="">
              <p class="name">{{item.name}}</p>
              <div class="desc">{{item.desc}}</div>
            </div>
          </div>
          <div class="scroll-box" ref="scrollBox">
            <div class="list-box">
              <div v-for="(item, index) in advantageData" :key="index" class="item">
                <img :src="`/images/auth/icon-${index + 1}.svg`" alt="">
                <p class="name">{{item.name}}</p>
                <div class="desc">{{item.desc}}</div>
              </div>
            </div>
          </div>
          <div class="scroll-btn">
            <a-icon type="caret-right" v-if="!isScrollBottom" @click="handleScrollBtn(true)"/>
            <a-icon type="caret-left" v-else @click="handleScrollBtn(false)"/>
          </div>
        </div>
      </div> -->
      <div class="container">
        <div class="title">申请个人账号认证<span class="desc">（适用于个人日常学习使用的账号）</span></div>
        <div class="auth-list-box">
          <div class="item" @click="goRealName">
            <div class="logo">
              <img src="/images/auth/icon-realname-auth.svg" alt="">
            </div>
            <div class="info">
              <p class="name">实名认证<a-icon type="right" /></p>
              <p class="desc">认证后可参与平台奖励、收益结算</p>
            </div>
          </div>
          <!-- <div v-for="(item, index) in list[1]" :key="index" class="item" @click="goRealName">
            <div class="logo" v-if="item.icon">
              <img :src="item.icon" alt="">
            </div>
            <div class="info">
              <div class="name">
                <div>{{item.name}}</div>
                <a-icon type="right" />
              </div>
              <p class="desc">{{item.desc}}</p>
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="container">
        <div class="title">申请官方账号认证<span class="desc">（适用于企业官方运营的账号）</span></div>
        <div class="auth-list-box">
          <div v-for="(item, index) in list[2]" :key="index" class="item" @click="goAuthForm(item.type, item.id)">
            <div class="logo" v-if="item.icon">
              <img :src="item.icon" alt="">
            </div>
            <div class="info">
              <div class="name">
                <div>{{item.name}}</div>
                <a-icon type="right" />
              </div>
              <p class="desc">{{item.desc}}</p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 个人认证 -->
    <div v-else-if="authType === 2" class="yet-auth-list">
      <!-- 实名认证 -->
      <div class="real-auth">
        <div class="auth-title">
          <div class="left">实名认证</div>
        </div>
        <div class="auth-item-box">
          <!-- 没有实名认证 -->
          <div v-if="!real_info.status" class="not-real-auth">
            <img src="/images/icon-renzheng-none.svg" alt="">
            <p class="msg-text" >
              完成实名认证后，可参与智库收益分成，赚取现金收益
            </p>
            <div class="func-btn" @click="goRealName">
              <span>立即认证</span>
              <img src="/images/auth/Line-right.svg" alt="" srcset="">
            </div>
          </div>
          <div class="real-name-info" v-else>
            <div class="main-info">
              <img class="status-icon" :src="statusIcon" alt="" />
              <p class="msg-text" >
                {{statusText}}
              </p>
              <div v-if="real_info.status != 1" class="func-btn" @click="goRealName">
                <span>编辑</span>
                <!-- <span v-if="[2, 4, 5].includes(real_info.status * 1)">重新提交</span> -->
                <img src="/images/auth/Line-right.svg" alt="" srcset="">
              </div>
            </div>
            <div class="sub-info">
              {{statusSubText}}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="else-auth">
        <div v-for="(item, index) in cert" :key="index">
          <div class="auth-title">
            <div class="left">{{item.name}}</div>
            <div class="right" @click="goAuthForm(1, item.id, false)">添加职称认证<img src="/images/auth/1-icon-2-5-round-add.svg" alt=""></div>
          </div>
          <div class="auth-item-box" v-if="item.cert_info && item.cert_info.length > 0">
            <div class="item" v-for="(certItem, certIndex) in item.cert_info" :key="certIndex">
              <div class="left">
                <p class="name">{{certItem.cert_name}}</p>
                <div class="info">
                  {{getCertNameValue(certItem.form_user_option)}}
                </div>
              </div>
              <div class="right" @click="goAuthForm(1, item.id, true, certItem.id)">
                <div class="edit" v-if="[0, 1].includes(certItem.status)">
                  <span>编辑</span>
                  <img src="/images/auth/Line-right.svg" alt="" srcset="">
                </div>
                <div class="err-tips" v-else>
                  <div>
                    <span>{{authStatus(certItem.status)}}</span>
                    <img src="/images/auth/Line-right.svg" alt="" srcset="">
                  </div>
                  <div class="err-text">{{certItem.reason}}{{certItem.remark}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 官方认证 -->
    <!-- <div v-else class="official-auth">
      <div class="official-title">官方账号认证信息</div>
      <div class="official-box">
        <div class="official-item">
          <div class="label">认证名称</div>
          <div class="info">{{officialAuth.name}}</div>
        </div>
        <div class="official-item">
          <div class="label">认证类型</div>
          <div class="info">官方账号认证</div>
        </div>
        <div class="official-item">
          <div class="label">{{officialAuth.cert_info[0].cert_name}}</div>
          <div class="info">{{getCertNameValue(officialAuth.cert_info[0].form_user_option)}}</div>
        </div>
        <div class="official-item">
          <div class="label">认证状态</div>
          <div class="info" @click="goAuthForm(2, officialAuth.id, true, officialAuth.cert_info[0].id)">
            <img v-if="[2, 3].includes(Number(officialAuth.cert_info[0].status))" src="/images/auth/icon-status-error.svg" alt="" class="err">
            {{authStatus(officialAuth.cert_info[0].status)}}
            <img src="/images/auth/1-icon-2-3-triangle-right.svg" alt="">
          </div>
        </div>
      </div>
      <div class="official-title">更多信息</div>
      <div class="official-box">
        <div class="official-item" v-for="(item, index) in officialAuth.cert_info[0].form_user_option" :key="index">
          <div v-if="item.value">
            <div class="label">{{item.name}}</div>
            <div class="info" v-if="item.type != 9">{{ item.value }}</div>
            <div class="info" v-else>
              <img :src="item.value" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="edit-btn" @click="goAuthForm(2, officialAuth.id, true, officialAuth.cert_info[0].id)">编辑</div>
    </div> -->
  </div>
</template>
<script>
export default {
  data () {
    return {
      authType: 1,
      list: [],
      isScrollBottom: false,
      advantageData: [
        {
          name: '参与结算，赚取收益',
          desc: '发布付费内容，获取平台创作补贴，提现收益资金'
        },
        {
          name: '专属认证标识',
          desc: '彰显身份'
        },
        {
          name: '官方流量扶持',
          desc: '账号、作品获得更多曝光展示的机会'
        },
        {
          name: '智库官方合作',
          desc: '优先成为官方伙伴，活动联运'
        },
        {
          name: '智库企业主页',
          desc: '官方账号认证可获得个性化企业主页，获取商业...'
        },
        {
          name: '更多福利',
          desc: '敬请期待'
        }
      ],
      real_info: {},
      cert: [],
      template_config: [],
      officialAuth: {}
    }
  },
  mounted () {
    this.initRealNameInfo()
    this.$refs.scrollBox.addEventListener('scroll', this.handleTopScroll)
  },
  computed: {
    statusText() {
      if (this.real_info.status == 1) { //待审核
        return `${this.real_info.name}（实名审核中）`
      }
      if (this.real_info.status == 2) { //未通过
        return `${this.real_info.name}（实名审核未通过）`
      }
      if (this.real_info.status == 3) { //已生效
        return `${this.real_info.name}（已实名）`
      }
      if (this.real_info.status == 4) { //已禁用
        return `${this.real_info.name}（实名状态有误）`
      }
      if (this.real_info.status == 5) { //已过期
        return `${this.real_info.name}（实名已过期）`
      }
      return ''
    },
    statusSubText() {
      if (this.real_info.status == 1) { //待审核
        return '认证正在审核中，预计1-3个工作日审核通过'
      }
      if (this.real_info.status == 2) { //未通过
        return `认证审核未通过，请及时更新信息重新提，未通过原因：${this.real_info.reason}`
      }
      if (this.real_info.status == 3) { //已生效
        return `身份证号 ${this.real_info.certificate_number}`
      }
      if (this.real_info.status == 4) { //已失效
        return `认证已被禁用，请及时更新信息重新提交`
      }
      if (this.real_info.status == 5) { //已过期
        return '认证已过期，请及时更新信息重新提交'
      }
      return ''
    },
    statusIcon() {
      let status = this.real_info.status || ''
      switch (status) {
        case 3:
          return '/images/icon-status-success.svg'
        case 1: 
          return '/images/icon-status-wait.svg'
        case 2:
        case 4:
        case 5:
          return '/images/icon-status-error.svg'
        default:
          return '/images/icon-renzheng-none.svg'
      }
    }
  },
  created () {
    this.getAuthList()
  },
  methods: {
    getCertNameValue (arr) {
      let title = '标题'
      arr.forEach((i) => {
        if (i.map_col === 'cert_name') {
          title = i.desc
        }
      })
      return title
    },
    authStatus (val) {
      const obj = {
        0: '待审核',
        1: '已通过',
        2: '已失效',
        3: '未通过'
      }
      return obj[val]
    },

    async getAuthList () {
      const res = await AuthService.applyCertList()
      this.list = res.list
      this.authType = res.type
      if (res.type == 2 || res.type == 3) {
        this.real_info = res.realName || {}
        this.cert = res.cert || []
        this.template_config = res.template_config || []
      }
      if (res.type == 3) {
        const officialAuth = res.cert.filter(i => i.cert_info.length > 0)[0] || {}
        this.officialAuth = officialAuth
      }
    },

    goAuthForm (type, id, isEdit = false, certItemId) {
      this.$router.push({
        path: '/bpy_authForm',
        query: { type, id, isEdit, certItemId }
      })
    },
    
    // 监听滚动
    handleTopScroll (e) {
      const scrollTop = e.target.scrollLeft
      const windowHeight = e.target.clientWidth
      const scrollHeight = e.target.scrollWidth - 180
      // 滚动条到底部
      this.isScrollBottom = scrollTop + windowHeight > scrollHeight
    },

    // 左右滚动
    handleScrollBtn (e) {
      // this.$refs.scrollBox.scrollIntoView({block: e ? 'end' : 'start'})
      this.$refs.scrollBox.scrollLeft = e ? this.$refs.scrollBox.scrollWidth : 0
    },

    async initRealNameInfo() {
      let res = await AuthService.realNameInfo({})
      if (res) {
        this.real_info = res || {}
      }
    },

    goRealName() {
      if (this.real_info.status != 1) {
        this.$router.push({
          path: '/bpy_realNameInfo',
          query: this.$route.query
        })
      }
    }
  }
}
</script>
